import { createContext, useState } from 'react';

const DataGridContext = createContext({
  filteredUsers: [],
  setFilteredUsers: (setFilteredUsers) => {},
});

export function DataGridContextProvider(props) {

  const [filteredDataGridUsers, setFilteredDataGridUsers] = useState([]);
  const [tab, setTab] = useState("Users");
  
  function updateFilteredDataGridUsers(userIDs) {
    setFilteredDataGridUsers(userIDs);
  }

  function updateTabName(tabName) {
    setTab(tabName);
  }

  const context = {
    filteredDataGridUsers: filteredDataGridUsers,
    updateFilteredDataGridUsers: updateFilteredDataGridUsers,
    tab: tab,
    updateTabName: updateTabName,
  };

  return <DataGridContext.Provider value={context}>
    {props.children}
  </DataGridContext.Provider>
}

export default DataGridContext;
