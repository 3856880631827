import React,{useContext, useState} from 'react'

import { Button } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { CircularProgress} from "@mui/material";

const DeleteWebsiteDetails = (props) => {

  console.log("DeleteWebsiteDetails props", props);

  // console.log("websiteDetails", websiteDetails);

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading]             = useState("");
  const websiteDetails = props?.details.websiteDetails;
  const toggleRefresh = props?.details.toggleRefresh;

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const handleDeleteWebsite = (e) => {
    e.preventDefault();
    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v2/datamining/website/' + websiteDetails.id, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.status === "success") {
        console.log("Successfully deleted website...");
        setIsLoading(false);
        props?.details.setToggleRefresh(!toggleRefresh);
        handleCloseModal();
        modalCtx.setPageUpdate({page: "datamining"});
      } else {
        console.log("Failed to delete website...");
      }
    })
    .catch(err => console.error("Failed to delete website...", err));

  }

  return (
    <div className='modal'>
      {isLoading ? 
      <section style={{ minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></section> 
      :
      <>
    <p style={{ margin: "5px auto 30px" }}>Are you sure? This deletes all data related to this website.</p>
    <div className="footer" style={{ margin: "15px auto 0" }}>
      <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
      <Button type="submit" className="confirm-btn" onClick={(e) => handleDeleteWebsite(e)}>Delete</Button>
    </div>
    </>
    }
    </div>
  )
}

export default DeleteWebsiteDetails;
