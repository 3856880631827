import React, { useState } from 'react'

export default function LogsPage() {
    const [selectedServer, setSelectedServer] = useState(1);
  return (
      <div className='logs-page-container'>
          <div className='page-header'>
              <h1>LOGS</h1>
          </div>
          <div className='page-body'>
              <div className='server-types'>
                  <div className='staging-server' onClick={(e) => setSelectedServer(1)}>
                      <p className={ selectedServer == 1 ? 'active' : '' }>Staing Server</p>
                      <div className={ selectedServer == 1 ? 'underline' : '' }></div>
                  </div>
                  <div className='production-server' onClick={(e) => setSelectedServer(2)}>
                      <p className={ selectedServer == 2 ? 'active' : '' }>Production Server</p>
                      <div className={ selectedServer == 2 ? 'underline' : '' }></div>
                  </div>
              </div>
              <div className='logs'>
                  
              </div>
          </div>
      </div>
  )
}
