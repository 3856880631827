import { createContext, useState } from "react";

const UsersContext = createContext({
  allUsers: [],
});

export function UsersContextProvider(props) {

  const [allUsers, setAllUsers]                   = useState([]);
  const [assessmentDetails, setAssessmentDetails] = useState();
  const [assessmentId, setAssessmentId]           = useState(1);
  const [pageUpdate, setPageUpdate]               = useState({});

  // console.log("usersCtx allUsers", allUsers);
  // console.log("usersCtx assessmentDetails", assessmentDetails);
  // console.log("usersCtx assessmentId", assessmentId);

  function setPageUpdateHandler(data) {
    setPageUpdate(data);
  }

  const context = {
    allUsers: allUsers,
    setAllUsers: setAllUsers,
    assessmentDetails, 
    setAssessmentDetails, 
    assessmentId, 
    setAssessmentId 
  };

  return (
    <UsersContext.Provider value={context}>
      {props.children}
    </UsersContext.Provider>
  );
}


export default UsersContext;
