// Global Helper Functions 

import { format, utcToZonedTime } from "date-fns-tz";

const showSidebar = () => {
  document.getElementsByClassName("menu")[0]?.classList.remove("hide-sidebar-menu");
  document.getElementsByClassName("bottom-links")[0]?.classList.remove("hide-sidebar-menu-bottom-links");
  document.getElementsByClassName("main")[0]?.classList.remove("hide-sidebar-expand-main");
  document.getElementsByClassName("menu")[0]?.classList.add("show-sidebar-menu");
  document.getElementsByClassName("bottom-links")[0]?.classList.add("show-sidebar-menu-bottom-links");
  document.getElementsByClassName("main")[0]?.classList.add("show-sidebar-shrink-main");
}

const hideSidebar = () => {
  document.getElementsByClassName("menu")[0]?.classList.remove("show-sidebar-menu");
  document.getElementsByClassName("bottom-links")[0]?.classList.remove("show-sidebar-menu-bottom-links");
  document.getElementsByClassName("main")[0]?.classList.remove("show-sidebar-shrink-main");
  document.getElementsByClassName("menu")[0]?.classList.add("hide-sidebar-menu");
  document.getElementsByClassName("bottom-links")[0]?.classList.add("hide-sidebar-menu-bottom-links");
  document.getElementsByClassName("main")[0]?.classList.add("hide-sidebar-expand-main");
}

const getDateString = (createdAt) => {
  let dateString = createdAt?.replace(/ /g,"T");
  const dateArr = new Date(dateString)?.toDateString()?.split(" ")?.slice(1, 4);
  // show YY instead of YYYY 
  // const dateStr = dateArr[0] + " " + dateArr[1] + ", " + dateArr[2].slice(2, 4);
  const dateStr = dateArr[0] + " " + dateArr[1] + ", " + dateArr[2];
  return dateStr;
}

const getLocalTimezoneName = () => {
  const today = new Date();
  const short = today.toLocaleDateString(undefined);
  const full = today.toLocaleDateString(undefined, { timeZoneName: 'short' });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
    
    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
}

const centralTimeZone = 'America/Chicago';
const getCentralDate = (date) => {
  // adding Z is for zero offset since datetime is already in UTC 
  const utcDate = new Date(date?.replace(/ /g,"T") + "Z").toISOString(); // UTC Time
  // let localeTime = new Date(utcDate).toLocaleTimeString([], { hour12: false });
  // let localeDateTime = "Local " + (new Date(utcDate).toLocaleString([], { hour12: false }));
  // console.log("localeDateTime", localeDateTime);
  // const formattedDate = format(centralTimeDate, 'MMMM dd, yyyy hh:mm:ss a');
  const centralTimeDate = utcToZonedTime(utcDate, centralTimeZone);
  // const dateCT = format(centralTimeDate, 'MMM dd, yyyy');
  // const timeCT = format(centralTimeDate, 'HH:mm:ss'); // 24 hour format
  // console.log("date:", date, dateCT);
  return format(centralTimeDate, 'MMM dd, yyyy');
}

const getCentralDateTime = (date) => {
  // adding Z is for zero offset since datetime is already in UTC 
  const utcDate = new Date(date?.replace(/ /g,"T") + "Z").toISOString(); // UTC Time
  const centralTimeDate = utcToZonedTime(utcDate, centralTimeZone);
  return format(centralTimeDate, 'MMM dd, yyyy hh:mm:ss a');
}


export { showSidebar, hideSidebar, getDateString, getCentralDate, getCentralDateTime, getLocalTimezoneName };
