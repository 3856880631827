import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import axios from 'axios';

import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';

const ClientAdminDelete = ({ details }) => {

  console.log("props details", details);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleDeleteAccount = () => {
    axios.delete(process.env.REACT_APP_API_URI + '/v1/accounts/' + details?.id + '/administrators', {
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
    .then(response => response.data)
      .then(result => {
        console.log(result);
        if (result.status === "success") {
          modalCtx.closeModal();
          modalCtx.setDetails(null);
          // modalCtx.setPageUpdate({page: "goals"});
        } else {
          console.log("failed to delete");
        }
      }).catch(err => console.error(err));
  }

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }


  return (
    <div className="modal">
      <p>Are you sure you want to delete this client administrator?</p>

      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => { handleDeleteAccount() }}>Yes</Button>
      </div>
    </div>
  )
}

export default ClientAdminDelete;
