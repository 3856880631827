import react, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { Paper, Grid, TextField, CardContent, Button, CircularProgress } from '@material-ui/core';
import { Checkbox, Snackbar } from '@material-ui/core';
import Logo from '../assets/img/bleat-logo-squre.svg';
// import Logo from '../assets/img/logo.svg';

import '../App.scss';

import AuthContext from '../store/AuthContext.js';

import Welcome from '../assets/img/login/welcome.png';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined.js';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined.js';

const Login = (props) => {

	let navigate                                = useNavigate();
	const authCtx                               = useContext(AuthContext);

	const [isSubmitting, setIsSubmitting] 			= useState(false);
	const [email, setEmail]                     = useState('');
	const [password, setPassword]               = useState('');
	const [redirect, setRedirect]               = useState(false);
	const [openSnackbar, setOpenSnackbar]       = useState(false);
	const [forgotPassword, setForgotPassword]   = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("Sorry, email or password incorrect");
	const [showPassword, setShowPassword] = useState(false);
	const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

	const checkForEnter = (e) => {
		if (e.keyCode === 13) {
			submit();
		}
	}

	console.log("API", process.env.REACT_APP_API_URI );

	const submit = () => {		

		if(emailRegex.test(email)) {

			setIsSubmitting(true);
			
			fetch(process.env.REACT_APP_API_URI + '/v1/adminlogin', {
				method: 'POST',
				mode: 'cors',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					email,
					password
				})
			})
			.then((response) => response.json())
			.then((data) => {
				setIsSubmitting(false);
				console.log(data);
				if (data.status == "success") {
					const tokenExpiration = new Date(new Date().getTime() + 86400000);
					authCtx.login(
						data.data.token,
						data.data.userId,
						data.data.userName,
						data.data.userEmail,
						data.data.userScope,
						data.data.accountId,
						tokenExpiration
					);
					navigate("/assessments", { replace: true });
				} else {
					setSnackbarMessage(data.message);
					setOpenSnackbar(true);
				}
			});
		} else {
			console.error("Email Invalid!");
		}

	}

	const passwordReset = () => {
		if(emailRegex.test(email)) {
			console.log("Email Valid!");
			// // change api route later 
			// fetch(process.env.REACT_APP_API_URI + '/v1/passwordreset', {
			// 		method: 'POST',
			// 		mode: 'cors',
			// 		headers: { 'Content-Type': 'application/json' },
			// 		body: JSON.stringify({ email })
			// 	}).then(response => {
			// 			return response.json();
			// 		}).then(data => {
			// 				console.log(data);
			// 				if (data.status == "success") {
			// 	setOpenSnackbar(true);
			// 	setSnackbarMessage("Password reset link has been sent to your registered email.");
			// 	// navigate("/login", { replace: true });
			// } else {
			// 		setSnackbarMessage(data.message);
			// 		setOpenSnackbar(true);
			// 	}
			// });
		} else console.error("Email Invalid!");
		console.log(email);
	}

	function changeEmail(event) {
		setEmail(event.target.value);
	}

	function changePassword(event) {
		setPassword(event.target.value);
	}

	if (!forgotPassword) {
		return (
			<div className="login-register">

				<div className="login">
					<img src={Logo} alt="Bleat Logo" className='bleat-logo' />

					<div className="form-heading">
						<h1>Hello</h1>
						<p>Sign in to your account</p>
					</div>

					<div className="form-wrapper">
						<form onKeyDown={(e) => checkForEnter(e)}>
							<Grid container spacing={4}>

								<Grid xs={12} item>
									<p className='helper-text'>Username</p>
									<TextField onChange={changeEmail} value={email} variant="outlined" fullWidth required />
								</Grid>

								<Grid xs={12} item>
									<p className='helper-text'>Password</p>
									<div className="password-container">
										<TextField onChange={changePassword} value={password} type = {!showPassword ? "password" : "text"} variant="outlined" fullWidth required />
										<span className='eye-icon' onClick={()=>{
											showPassword ? setShowPassword(false) : setShowPassword(true)
										}}>
											{showPassword ? <VisibilityOffOutlinedIcon/> : <RemoveRedEyeOutlinedIcon/>}
										</span>
									</div>
								</Grid>

							</Grid>

							{/* <div className="remember-forgot-wrapper">
								<div className="circle-checkbox">
									<Checkbox name="checked8" color="primary" />Remember Me
								</div>
								<div className="forgot-password">Forgot Password ?</div>
							</div> */}
							{
								isSubmitting ? <Button className="outlineBtn"><CircularProgress size={20} /></Button> :
								<Button className="outlineBtn" onClick={submit}>Sign in</Button>
							}
							{/* <div className="no-account-wrapper">
									Don't have an account?
									<Link to="/register"> join free today</Link>
							</div> */}
							
							{/* <p className="forgot-password">Forgot your password? <a 
								onClick={(e) => {
									e.preventDefault();
									setForgotPassword(true);
								}}>Click here</a></p> */}

						</form>
					</div>
					<Snackbar open={openSnackbar} autoHideDuration={1000} message={snackbarMessage}></Snackbar>


				</div>
			</div>
		)
	} else return (

		<div className="login-register">
			<div className="login">
				<img src={Logo} alt="Bleat Logo" className='bleat-logo' />
				<div className="form-heading">
					<h1>Hello</h1>
					<p>Reset Your Password</p>
				</div>

				<div className="form-wrapper">
					<form>
						<Grid container spacing={4}>

							<Grid xs={12} item>
								<p className='helper-text'>Your Email</p>
								<TextField onChange={changeEmail} value={email} variant="outlined" fullWidth required />
							</Grid>
						</Grid>

						<Button className="outlineBtn" onClick={passwordReset}>Send Reset Link</Button>

						<p className="sign-in">Want to Sign In Instead? <a href="/login">Click here</a></p>
					</form>
				</div>
				<Snackbar open={openSnackbar} autoHideDuration={1000} message={snackbarMessage}></Snackbar>

			</div>
		</div>
	);
}

export default Login;