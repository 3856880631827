import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import personImg from '../assets/img/person-img1.png';

export default function NotificationPage() {
	
	const [notify, setNotify] = useState([])
	const [checked, setChecked] = useState([1]);

	const notificationList = ['The API is down', 'If the XYZ assessment is down']

	const handleChange = (e) => {
	} 
 const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
      <div className='notification-page-container'>
				<div className='page-header'>
						<h1>Notification Setup</h1>
				</div>
				<div className='card'>
					<div className='content'>
						<h2>Notification Setup</h2>
						<div className='notification-dropdown'>
							<p>Select Notification</p>
							<FormControl fullWidth size='small'>
									<Select
										labelId="demo-select-small-label"
										id="demo-select-small"
										value={notify}
										multiple
										placeholder='Select Notification'
										onChange={handleChange}>
										{ notificationList.map((item, index) => <MenuItem key={ index } value={ item } >
											<ListItemText primary={item} />
											<CheckBox checked={ notificationList.indexOf(item) > -1 } />
										</MenuItem>)}
									</Select>
							</FormControl>

					</div>
					<div className=''>
						<p>Select Users to Notify</p>
						<TextField id="outlined-basic" label="Search Frequency Memebers" variant="outlined" size='small' fullWidth />
						<div className='members-list'>
							<List fullWidth>
								{[0, 1, 2, 3, 4].map((value) => {
									const labelId = `checkbox-list-secondary-label-${value}`;
									return (
										<ListItem
											key={value}
											secondaryAction={
												<Checkbox
													edge="end"
													onChange={handleToggle(value)}
													checked={checked.indexOf(value) !== -1}
													inputProps={{ 'aria-labelledby': labelId }}
												/>
											}
											disablePadding
										>
											<ListItemButton>
												<ListItemAvatar>
													<Avatar
														alt={`Avatar n°${value + 1}`}
														src={personImg}
													/>
												</ListItemAvatar>
												<ListItemText id={labelId} primary={`Line item ${value + 1}`} />
											</ListItemButton>
										</ListItem>
									);
								})}
							</List>
						</div>
						<div className='buttons'>
							<div className='cancel-btn'>CANCLE</div>
							<div className='notify-btn'>NOTIFY</div>
						</div>
					</div>
					</div>
				</div>
      </div>
  )
}
