import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import sidebarArrow from '../assets/img/sidebarArrow.png'
import { GraphicEq, Equalizer, PeopleAlt, Assessment, Translate, Explore, ExitToApp, AttachMoney, GroupWork, FindReplace, ChevronRight, ChevronLeft } from '@material-ui/icons';
import { Link } from "react-router-dom";
// import Logo from '../assets/img/logo.svg';
import Logo from '../assets/img/bleat-logo-round.svg';
import apiLogo from '../assets/img/api-logo.svg';
import logsLogo from '../assets/img/logs-logo.svg'

import AuthContext from '../store/AuthContext.js';
import UsersContext from '../store/UsersContext';
import SidebarContext from '../store/SidebarContext.js';



import '../App.scss';
import { East } from '@mui/icons-material';

const noSideBarRoutes = ["/login", "/register", "/builder"];

function SideBar() {

	const authCtx 								      = useContext(AuthContext);
	const usersCtx = useContext(UsersContext);
	const { pathname } 						      = useLocation();
	const navigate      								= useNavigate();

	// console.log(pathname);
	
	const isLoggedIn 							      = authCtx.isLoggedIn;

	const [isLoading, setIsLoading]     = useState(true);
  const [assessments, setAssessments] = useState([]);
	const [hideActive, setHideActive]   = useState("");
	const [currentAssessment, setCurrentAssessment] = useState(null);
	const sidebarCtx = useContext(SidebarContext);
	const [isHidden, setIsHidden] = useState(false);

	useEffect(() => {
    setIsLoading(true);
    getAssessments();
  }, []);

	useEffect(() => {
    console.log("sidebarCtx.isOpen",sidebarCtx.isOpen)
  }, [sidebarCtx.isOpen]);

	useEffect(() => {
		if(pathname) {
			let path = pathname.split("/");
			// console.log(path);
			if(path[1] === "assessments" && path[2]) {
				assessments.forEach(assessment => {
					if(assessment?.id?.toString() === path[2]?.toString()) {
						setCurrentAssessment(assessment.id);
						setHideActive("")
					}
				})
			} else setHideActive(" hide-active");
		}

	}, [assessments, pathname]);

  const getAssessments = () => {
    fetch(process.env.REACT_APP_API_URI + "/v1/admin/assessmentsByClient/" + authCtx.accountId, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
      setIsLoading(false);
      setAssessments(data.data);
    });
  }
	
	if (noSideBarRoutes.some((item) => pathname.includes(item))) return null;

	return (
		<div className={sidebarCtx.isOpen ? 'menu close-sidebar' : 'menu'}>
			<div className="logo-arrow-container" style={{flexDirection:sidebarCtx.isOpen? "column-reverse" : "" }}>
				<div className={ sidebarCtx.isOpen ? "company-logo company-logo-closed" : "company-logo"}>
					<img src={Logo} alt="company logo" />
				</div>
				<div className="sidebar-arrow" >
					{
						sidebarCtx.isOpen ? <div className='right-arrow-logo' onClick={()=>{ 
							sidebarCtx.toggleSidebar();
							if ( isHidden ) { setIsHidden(false) }
							else { setTimeout(() => { setIsHidden(true); }, 400); }
						}}> <ChevronRight/> </div> : <div className="left-arrow-logo" onClick={()=>{
							sidebarCtx.toggleSidebar()
							if ( isHidden ) { setIsHidden(false) }
							else { setTimeout(() => { setIsHidden(true); }, 400); }
						}}><ChevronLeft/></div>
					}
				</div>
			</div>
			<div className='greeting'>welcome <br />Stephen!</div>
			<nav>
				{/* {isLoggedIn && !sidebarCtx.isOpen ? (<h3 className="welcome-user">Welcome,<br/>{authCtx?.userName?.split(" ")[0]}!</h3>) : <></>} */}
				
				{/* 	<h4 className="assessments-header">Assessments:</h4> */}
				{/* <div className={ authCtx.accountId == 0 ? "assessments-list" : "assessments-list account" }>
					<ul>
						{
							assessments?.map((assessment, index) => {
								let activeClass = "";
								let replace = /Church Management Software/gi;
								let assessmentNameFull = assessment?.title.toString();
								let assessmentName = assessment?.title.toString().replace(replace, "ChMS").trim();
								assessmentName = assessmentName.length > 24 ? assessmentName.substring(0, 24) + "..." : assessmentName;
								if(assessment?.id?.toString() === currentAssessment?.toString()) {
									activeClass = " active-assessment ";
								} else {
									activeClass = "";
								}

								return (
									<li title={assessmentNameFull} style={ isHidden ? { display:"flex" , justifyContent:"center" } : {}} className={"assessment-item" + activeClass + hideActive} key={assessment?.id} onClick={() => {

										navigate("/assessments/" + assessment?.id, { replace: true })}} ><span className="assessment-num" style={{ marginRight: isHidden ? "0" : ""}}>{index + 1} </span>{ isHidden ? <></> : <span className={`fade-out ${sidebarCtx.isOpen ? 'fade-in' : ''}`} >{assessmentName}</span> }</li>
								)
							})
						}
					</ul>					
				</div> */}

				<div className="bottom-links" style={ sidebarCtx.isOpen ? {left:"1%"} : {} }>
					<div className="links-container">
						<Link to="/api" className="nav-link"><img src={ apiLogo } />&nbsp;&nbsp; { isHidden ? <></> : <span style={{ opacity : sidebarCtx.isOpen ? "0" : "1" , transition : '1s' }}> API </span> } </Link>
						<Link to="/assessments" className="nav-link"><Assessment />&nbsp;&nbsp; { isHidden ? <></> : <span style={{ opacity : sidebarCtx.isOpen ? "0" : "1" , transition : '1s' }}> Assessments </span> } </Link>
						<Link to="/logs" className="nav-link"><img src={ logsLogo } />&nbsp;&nbsp; { isHidden ? <></> : <span style={{ opacity : sidebarCtx.isOpen ? "0" : "1" , transition : '1s' }}> Logs </span> } </Link>
						<a className="logout" onClick={() => authCtx.logout()}><ExitToApp />&nbsp;&nbsp;{ isHidden ? <></> : <span style={{ opacity : sidebarCtx.isOpen ? "0" : "1" , transition : '1s' }} >Log Out</span>}</a>
					</div>
				</div>
			</nav>
			
		</div>
	);
}
export default SideBar;