import React,{useContext} from 'react'

import { Button } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';

const DeleteContactDetails = () => {

    const modalCtx = useContext(ModalContext);
    const handleCloseModal = () => {
      modalCtx.setDetails(null);
      modalCtx.closeModal();
    }

  return (
    <div className='modal'>
    <div>Are you sure?</div>
    <div className="footer" style={{ margin: "15px auto 0" }}>
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
        <Button type="submit" className="confirm-btn">Delete</Button>
    </div>
    
    </div>
  )
}

export default DeleteContactDetails