import { Box, Button, CircularProgress, IconButton, MenuItem, Modal, TextField, makeStyles } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,  } from '@material-ui/core';
import AuthContext from '../store/AuthContext';
import { hideSidebar } from '../globalFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import ModalContext from '../store/ModalContext';
import DrawerContext from '../store/DrawerContext';
import { Clear } from '@material-ui/icons';

import editdetails from '../../src/assets/img/edit.svg'
import cross from '../../src/assets/img/cross.png'



const AssesmentPage = () => {
  const navigate = useNavigate()


  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [assessments, setAssessments] = useState("")
  const [editClientDetails, setEditClientDetails] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [title, setTitle] = useState("");
  const [clientName, setClientName] = useState("");
  const [sorting, setSorting] = useState({ column: null, direction: 'asc' });
  const [awsImageUrl, setAwsImageUrl] = useState("");
  const [upLoadingImage, setUpLoadingImage] = useState(false);
  const [editId, setEditId] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  


  const handleSort = (column) => {
    if (column !== 'actions') {
      setSorting((prevSorting) => ({
        column,
        direction: prevSorting?.column === column && prevSorting?.direction === 'asc' ? 'desc' : 'asc',
      }));
    }
  };
  const sortedIcon = (asc, title) => {
    return (
      // <IconButton aria-label="Sort" title="Sort" className={`arrow-updown-${title}`}>
      //   {asc == "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      // </IconButton>
      <>
        
      </>


    );
  };

  const sortedAssessments = [...assessments].sort((a, b) => {
    const isAsc = sorting.direction === 'asc';
    let comparison = 0;
    switch (sorting.column) {
      case 'title':
        comparison = a.title.localeCompare(b.title);
        break;
      case 'allUsers':
        comparison = (a.leaderCount + a.memberCount) - (b.leaderCount + b.memberCount);
        break;
      case 'leaders':
        comparison = a.leaderCount - b.leaderCount;
        break;
      case 'members':
        comparison = a.memberCount - b.memberCount;
        break;
      case 'date':
        comparison = new Date(a.date) - new Date(b.date);
        break;
      default:
        comparison = 0;
    }
    sortedIcon(sorting?.direction);
    return isAsc ? comparison : -comparison; 
 
  });
 
  function fncEditAssesment(data) {
    setEditClientDetails(true)
    setEditId(data?.id)
    setTitle(data?.title)
    getClients()
  }



  function getClients() {
    
    fetch(process.env.REACT_APP_API_URI + "/v1/accounts", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setClientsData(data?.data);
      });
  }
  




  function fncCross() {
    setEditClientDetails(false)
    setAwsImageUrl("")

  }
 
  function toggleDrawerHandler(drawer, id) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, id);
  }
  
  const toggleModalHandler = (modal, id) => {
    let details = {
      id: id,
      imageType: 'client-logo'
    }
    modalCtx.setDetails(modal, details);
    modalCtx.openModal();
  };


  useEffect(() => {
    setIsLoading(true)
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status === "success") {
          setIsLoading(false)
          setAssessments(data?.data);

        } else {
          setIsLoading(false)

        }
      }).catch(err => console.error(err));
  }, [])

  const getImageUrl = (e) => {
    e.stopPropagation();
    setUpLoadingImage(true)

    const formData = new FormData();
    formData.append('image', e.target.files[0]);

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + editId + '/updateImage', {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: formData,

    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          setAwsImageUrl(data?.imagePath)
          setUpLoadingImage(false)

        }
      }).catch(err => console.error(err));

  }
  
  const handleSubmit = (e) => {
    e.preventDefault();

    if (awsImageUrl && clientName && title ) {
      setIsCreating(true)

      const bodydata = {
        "clientName": clientName ,
        "assessmentTitle": title,
        "image": awsImageUrl
      }
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + editId + '/account/' + authCtx?.accountId , {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx?.token },
        body: JSON.stringify(bodydata),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          setIsCreating(false);
          if (data.status === "success") {
            console.log("successfully updated ...");
          }
        setEditClientDetails(false)

        })
        .catch((err) => console.error("failed to update...", err));
    }


  }




  const showSearchBar = () => (
    <div className="search-bar">
      <input type="search" name="" id="search-field" className="search-field" placeholder="Type to search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      {searchTerm && <Clear className="clear-search-icon" onClick={() => setSearchTerm("")} />}
    </div>
  );
  function getHighlightedText(text, highlight) {
    // Find the index of the match and the length of the highlight
    if (text?.length > 0) {
      const startIndex = text?.toLowerCase().indexOf(highlight.toLowerCase());
      const endIndex = startIndex + highlight.length;

      // If the highlight is not found, return the original text
      if (startIndex === -1) return text;
      // Otherwise, split the text and highlight the matching substring
      const parts = [
        text.substring(0, startIndex),
        text.substring(startIndex, endIndex),
        text.substring(endIndex),
      ];

      return (
        <Fragment>
          {parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={i} style={{ backgroundColor: "greenyellow" }}>{part}</span>
            ) : (<span key={i}>{part}</span>)
          )}
        </Fragment>
      );
    }
  };

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }


    if(isLoading ){

      return <section className="loader-section"><CircularProgress /></section>
    }

  const filteredData= sortedAssessments?.filter((data) => {
      const lowerCaseTitle = data?.title?.toLowerCase();
      const lowerCaseName = data?.name?.toLowerCase();
      const lowerCaseSearchTerm = searchTerm?.toLowerCase();
      return lowerCaseTitle && lowerCaseTitle?.includes(lowerCaseSearchTerm) || lowerCaseName && lowerCaseName?.includes(lowerCaseSearchTerm);
    })

  const noDataFound = filteredData?.length === 0;
  return (
      <div className="assessment-page-container" >
          <div className="page-header">
              <h1>Assessments </h1>
        <Button className="create-btn" onClick={() => { toggleDrawerHandler("create-assessment", null); }}  >Create</Button>
      </div>
        
        <TableContainer component={Paper}>
          <Table aria-label="simple table" >
            <TableHead>
              <TableRow>
              {/* <TableCell onClick={() => handleSort('date')} className='assesment-date'>Date  {sortedIcon(sorting?.direction)}</TableCell> */}
                <TableCell> </TableCell>
              <TableCell onClick={() => handleSort('title')} className='assesment-head'>Assessment {sortedIcon(sorting?.direction, "head")} </TableCell>
              <TableCell onClick={() => handleSort('allUsers')} className='assesment-user'>Client {sortedIcon(sorting?.direction, "user")}</TableCell>
              <TableCell onClick={() => handleSort('leaders')} className='assesment-leader'>Current Status  {sortedIcon(sorting?.direction, "leader")}</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredData.map((data, index) => {
              console.log(data);
              return (
              <TableRow key={data?.id}>
                  <TableCell>
                    <img src={data?.image} alt="" />
                </TableCell>
                <TableCell className='assesment-body'>
                  <div className="assesment-name">
                    <div className='editdetails'>
                      <div>{getHighlightedText(capitalizeWords(data?.title), searchTerm) || ""}</div>
                      <img src={editdetails} alt="edit" onClick={() => {fncEditAssesment(data) }} />

                    </div>
                    <div style={{ fontWeight: 300, color: "#9B9B9B" }}>
                      {data?.name}
                    </div>
                  </div>
                </TableCell>
                <TableCell>{data?.leaderCount + data?.memberCount} </TableCell>
                <TableCell>
                  <div className={`current-status ${index % 1 == 0 && 'online'} ${index % 3 == 0 && 'offline'} ${index % 7 == 0 && 'pause'}`}><p>online</p></div>
                </TableCell>
                <TableCell>
                  <div className='edit-container'>
                    <div className='view-btn'>Test</div>
                  </div>
                </TableCell>
              </TableRow>
            
           
              )}
            )}
          
          
          </TableBody>
         
        </Table>
        {noDataFound && searchTerm && (
          <div className='no-data-found'>No data Found</div>
        )}
        </TableContainer>
       

      
      {editClientDetails && (
        <div className="assesment-container" >
          <div className="modal-content">
            <div className="main-content">
              <form onSubmit={handleSubmit}>
              <div className="heading-container">
                <div className="heading">Edit Assessment Details</div>
                <img src={cross} onClick={() => { fncCross() }} alt="cross" />
              </div>
              <div className="items">
                <div className="label">Fav Icon</div>
                <label className="upload-container">
                  <input type="file" onChange={(e) => { getImageUrl(e) }} />
                  <img src={awsImageUrl} alt="upload" />
                  {upLoadingImage ? <section className="loader-section-uploaing"><CircularProgress /> Uploading</section> : ""}
                </label>

              </div>
              <div className="items">
                <div className="label">Assessment Title</div>
                <input className="text-box" type={"text"} placeholder={"Assessment Title"} defaultValue={title} onChange={(e)=>{setTitle(e.target.value)}} />
              </div>
              <div className="items">
                <div className="label">Client Name</div>
                  <TextField fullWidth className="form-control-add-question margin-top"
                    placeholder="Client Name"
                    value={clientName}
                    onChange={(e) => { setClientName(e.target.value) }}
                    label=""
                    select
                    variant="outlined"
                    required
                  >
                    {clientsData && clientsData.map((data) => (
                      <MenuItem key={data?.title} className="section-name" value={data?.title}>
                        {data?.title}
                      </MenuItem>
                    ))}
                  </TextField>
              </div>
                {isCreating ?
                  <button className="create-btn-submit" disabled> <CircularProgress /></button> :
                  <button className="create-btn-submit" type="submit" >Save Changes</button>
                }
              </form>
            </div>
           </div>
          </div>
      
      )}
    </div>
  )
}

export default AssesmentPage
