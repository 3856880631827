import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

export default function ApiPage() {
    const [showSetting, setShowSetting] = useState(false);
  return (
      <div className='api-page-container'>
          <div className='page-header'>
            <h1>API</h1>
          </div>
          <div className='status-setting'>
            <div className='setting'>
                <div className='current-status'><p className='current-status online'>online</p></div>
                <div onClick={(e) => setShowSetting(!showSetting)}> <SettingsOutlinedIcon style={{cursor:"pointer"}}/></div>
              </div>
              <div className='dropdown' style={{display : showSetting ? "block" : "none"}}>
                  <Link  to="/notification" className='notifications'>
                      <NotificationsOutlinedIcon />
                      <p>Notification Setup</p>
                  </Link >
              </div>
          </div>

          <div className='cpu-container'>
              <p>CPU Utilization</p>
          </div>
    </div>
  )
}
