import { createContext, useState } from 'react';

const SubDrawerContext = createContext({
  open: false,
  drawer: 'none',
  details: {},
  pageUpdate: {},
  setDetails: (setDetails) => {},
  setPageUpdate: (setPageUpdate) => {},
  openDrawer: (openDrawer) => {},
  closeDrawer: (closeDrawer) => {}
});

export function SubDrawerContextProvider(props) {

  const [open, setOpen] = useState(false);
  const [drawer, setDrawer] = useState('none');
  const [details, setDetails] = useState({});
  const [pageUpdate, setPageUpdate] = useState({});
  // const [prevDrawerDetails, setPrevDrawerDetails] = useState(null);

  function openDrawerHandler() {
    setOpen(true);
  }

  function closeDrawerHandler() {
  // function closeDrawerHandler(nested, drawer) {
    setOpen(false);
    // if(nested) {
      // setOpen(true);
      // setDrawer(drawer);
      // setDetails(prevDrawerDetails);
      // setPrevDrawerDetails(null);
    // }
  }

  function setDetailsHandler(drawer, data) {
    setDetails(data);
    setDrawer(drawer);
  }

  function setPageUpdateHandler(data) {
    setPageUpdate(data);
  }

  // function savePrevDrawerDetailsHandler(data) {
  //   setPrevDrawerDetails(data);
  // }

  // function getPrevDrawerDetailsHandler() {
  //   return prevDrawerDetails;
  // }

  const context = {
    open: open,
    drawer: drawer,
    details: details,
    pageUpdate: pageUpdate,
    setDetails: setDetailsHandler,
    setPageUpdate: setPageUpdateHandler,
    openDrawer: openDrawerHandler,
    closeDrawer: closeDrawerHandler,
    // savePrevDrawerDetails: savePrevDrawerDetailsHandler,
    // getPrevDrawerDetails: getPrevDrawerDetailsHandler,
  };

  return <SubDrawerContext.Provider value={context}>
    {props.children}
  </SubDrawerContext.Provider>
}

export default SubDrawerContext;
