import React, { useState, useContext, useEffect, Component } from "react";
import { Drawer } from "@material-ui/core";
import DrawerContext from "../store/DrawerContext.js";

function DetailsDrawer(props) {
  const drawerCtx = useContext(DrawerContext);

  function closeDrawer() {
    drawerCtx.closeDrawer();
  }

  console.log('drawerCtx.details',drawerCtx.details)

  const currentDrawer = () => {
    switch (drawerCtx.drawer) {
      
      default: return (<Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>);
    }
  };

  return <div>{currentDrawer()}</div>;
}
export default DetailsDrawer;
