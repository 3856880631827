import { createContext, useState } from 'react';

const SidebarContext = createContext({
  isOpen: false,
  selectedMenuItem: 'home',
  toggleSidebar: () => {},
  selectMenuItem: () => {},
});

export function SidebarContextProvider(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('home');

  function toggleSidebarHandler() {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }

  function selectMenuItemHandler(item) {
    setSelectedMenuItem(item);
  }

  const context = {
    isOpen: isOpen,
    selectedMenuItem: selectedMenuItem,
    toggleSidebar: toggleSidebarHandler,
    selectMenuItem: selectMenuItemHandler,
  };

  return (
    <SidebarContext.Provider value={context}>
      {props.children}
    </SidebarContext.Provider>
  );
}

export default SidebarContext;
