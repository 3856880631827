import React, { useEffect, useState, useContext } from 'react';

import SideBar from "./Components/SideBar";
import DetailsDrawer from "./Components/DetailsDrawer";
import DetailsSubDrawer from './Components/DetailsSubDrawer';
import DetailsModal from "./Components/DetailsModal";

// Pages
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import ApiPage from './Pages/ApiPage.jsx';
import LogsPage from './Pages/LogsPage.jsx';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AuthContext from './store/AuthContext.js';
import { DrawerContextProvider } from './store/DrawerContext.js';
import { SubDrawerContextProvider } from './store/SubDrawerContext';
import { ModalContextProvider } from './store/ModalContext.js';
import { DataGridContextProvider } from './store/DataGridContext';
import { UsersContextProvider } from './store/UsersContext';
import SidebarContext from './store/SidebarContext.js';
import AssesmentPage from './Pages/AssesmentPage.jsx';
import NotificationPage from './Pages/NotificationPage.jsx';

function App() {

	const authCtx = useContext(AuthContext);
	const sidebarCtx = useContext(SidebarContext);
	const [name, setName] = useState('');
	console.log(authCtx);

	return (
		<div className="App">

			<BrowserRouter>
				<UsersContextProvider>
					<DrawerContextProvider>
						<SubDrawerContextProvider>
							<ModalContextProvider>
								<DataGridContextProvider>
									{authCtx.isLoggedIn ? <SideBar /> : null}
									<main className={sidebarCtx.isOpen ? "main main-closed" : "main"}>
										<Routes>
											<Route path="/" exact index element={!authCtx.isLoggedIn ? <Navigate to='/login' /> : <Navigate to='/assessments' />} />
											<Route path="/login" exact index element={!authCtx.isLoggedIn ? <Login setName={setName} /> : <Navigate to='/assessments' />} />
											<Route path="/register" exact element={!authCtx.isLoggedIn ? <Register /> : <Navigate to='/login' />} />
											<Route path="/api" exact element={authCtx.isLoggedIn ? <ApiPage /> : <Navigate to='/login' />} />
											<Route path="/assessments" exact element={authCtx.isLoggedIn ? <AssesmentPage /> : <Navigate to='/login' />} />
											<Route path="/logs" exact element={authCtx.isLoggedIn ? <LogsPage /> : <Navigate to='/login' />} />
											<Route path='/notification' exact element={authCtx.isLoggedIn ? <NotificationPage /> : <Navigate to='/login' />} />
										</Routes>
									</main>
									<DetailsDrawer />
									<DetailsSubDrawer />
									<DetailsModal />
								</DataGridContextProvider>
							</ModalContextProvider>
						</SubDrawerContextProvider>
					</DrawerContextProvider>
				</UsersContextProvider>
			</BrowserRouter>

		</div>
	);
}

export default App;
