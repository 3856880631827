import React, { useState, useContext, useEffect, Component } from "react";
import { Drawer } from "@material-ui/core";
import DrawerContext from "../store/DrawerContext.js";
import SubDrawerContext from "../store/SubDrawerContext.js";
import UserDetailsDrawer from "./Drawers/UserDetailsDrawer.jsx";

function DetailsSubDrawer(props) {
  const subdrawerCtx = useContext(SubDrawerContext);

  function closeDrawer() {
    subdrawerCtx.closeDrawer();
  }

  console.log("subdrawerCtx.details",subdrawerCtx.details)

  const currentDrawer = () => {
    switch (subdrawerCtx.drawer) {
      
      case "user-details-subdrawer": return ( <Drawer anchor="right" open={subdrawerCtx.open} onClose={closeDrawer}><UserDetailsDrawer userDetails={subdrawerCtx.details} /></Drawer>);
      default: return (<Drawer anchor="right" open={subdrawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>);
    }
  };

  return <div>{currentDrawer()}</div>;
}
export default DetailsSubDrawer;
