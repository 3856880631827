import { useState, useContext, useEffect } from 'react';
import { Modal, Box } from '@material-ui/core';

import ModalContext from '../store/ModalContext.js';

import ClientAdminDelete from './Modals/ClientAdminDelete.jsx';
import DeleteWebsiteDetails from './Modals/DeleteWebsiteDetails.jsx';
import DeleteContactDetails from './Modals/DeleteContactDetails.jsx';


const DetailsModal = () => {

  const modalCtx = useContext(ModalContext);

  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 800,
    // height: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    // overflow: "scroll"
  };

  const editAssessmentStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const currentModal = () => {
		switch(modalCtx.modal) {
      case "delete-client-administrator": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><ClientAdminDelete details={modalCtx.details} /></Box></Modal>;
      case "website-details-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteWebsiteDetails details={modalCtx.details} /></Box></Modal>;
      case "contact-details-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteContactDetails details={modalCtx.details} /></Box></Modal>;

      default: return null;
    }
	}

  return (
    <div>{ currentModal() }</div>
  );

}

export default DetailsModal;
